import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Badge } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "badge"
    }}>{`Badge`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Badge from '@mfrm/mfcl/Badge'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Badges can be added to show small, simple details of information.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Badge} mdxType="Props" />
    <h2 {...{
      "id": "badge-types"
    }}>{`Badge Types`}</h2>
    <p>{`A badge can be of two types, primary and secondary.`}</p>
    <Playground __position={1} __code={'<Badge>Save $100</Badge>\n<Badge type=\"secondary\" style={{ marginLeft: \'5px\' }}>\n  Save $100\n</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge mdxType="Badge">Save $100</Badge>
  <Badge type="secondary" style={{
        marginLeft: '5px'
      }} mdxType="Badge">
    Save $100
  </Badge>
    </Playground>
    <h2 {...{
      "id": "adding-components"
    }}>{`Adding Components`}</h2>
    <p>{`You can pass components to the Badge component to inherit their styles and props.`}</p>
    <Playground __position={2} __code={'<Badge component={\'a\'} href=\"/\">\n  Click Me\n</Badge>\n<Badge\n  component={\'a\'}\n  href=\"/\"\n  type=\"secondary\"\n  style={{ marginLeft: \'5px\' }}\n>\n  Click Me\n</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge component={'a'} href="/" mdxType="Badge">
    Click Me
  </Badge>
  <Badge component={'a'} href="/" type="secondary" style={{
        marginLeft: '5px'
      }} mdxType="Badge">
    Click Me
  </Badge>
    </Playground>
    <h2 {...{
      "id": "custom-width-and-height-styles"
    }}>{`Custom Width and Height styles`}</h2>
    <Playground __position={3} __code={'<Badge style={{ width: \'200px\' }}>WideGuy</Badge>\n<Badge type=\"secondary\" style={{ height: \'50px\', marginLeft: \'5px\' }}>\n  TallMan\n</Badge>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge style={{
        width: '200px'
      }} mdxType="Badge">WideGuy</Badge>
  <Badge type="secondary" style={{
        height: '50px',
        marginLeft: '5px'
      }} mdxType="Badge">
    TallMan
  </Badge>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      